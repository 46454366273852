export const HOME = "/";
export const LOGIN = "/login";
export const APPROVALS = "/approvals";
export const OAUTH_CALLBACK = "/oauth/callback";

// Submission's routes
export const SUBMISSION_INDEX = "/submissions";
export const SUBMISSION_SHOW = "/submissions/:id";

// manual-approvals's routes
export const MANUAL_APPROVAL_INDEX = "/manual-approvals";
export const MANUAL_APPROVAL_SHOW = "/manual-approvals/:user";
export const MANUAL_APPROVAL_INDICATOR_SHOW =
  "/manual-approvals/:user/indicators/:indicator";

// Self approvals's routes
export const SELF_REVIEW_INDEX = "/self-review";

// Reviewers status route
export const REVIEWERS_STATUS = "/reviewers-status";

// Board review routes
export const BOARD_REVIEW_INDEX = "/review";
export const BOARD_REVIEW_SHOW = "/review/:id";
// Award review routes
export const AWARD_REVIEW_INDEX = "/award";
export const AWARD_REVIEW_SHOW = "/award/:id";

// Reports routes
export const KPI_REPORTS = "/report";
