import { PURGE } from "redux-persist";
import { HTTP_REQUEST } from "middleware/axios";
import * as actionTypes from "constants/actionTypes";
import { remoteUrl } from "config/env";

export function login(values) {
  return {
    ...values,
    type: actionTypes.LOGIN_SUCCESS,
  };
}

export function logout() {
  return {
    type: PURGE,
    result: () => null,
  };
}

export function loadUser() {
  return {
    [HTTP_REQUEST]: {
      method: "GET",
      baseURL: remoteUrl,
      url: "/api/profile",
      onSuccess: response => {
        return {
          response,
          type: actionTypes.AUTH_USER_LOADED,
        };
      },
    },
  };
}
