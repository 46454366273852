import React from "react";
import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";
import styles from "./CenterRegion.module.scss";

function CenterRegion() {
  return (
    <div className={styles.region}>
      <div className={styles["brand-container"]}>
        <div className={styles["brand-information"]}>
          <span className={styles["app-name"]}>
            <span>KPI (Beta)</span>
          </span>
        </div>
      </div>
      <div className={styles["search-container"]}>
        <div className={styles["search-box"]}>
          <div className={styles["fast-hero-search"]}>
            <form aria-label="Search" className={styles["search-form"]}>
              <SearchBox
                className={styles["search-input"]}
                placeholder="Search everything"
              />
            </form>
          </div>
        </div>
      </div>
      <div className={styles["icon-container"]} />
    </div>
  );
}

export default CenterRegion;
