import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import { applyMiddleware, compose, createStore } from "redux";
// App level imports
import axios from "lib/axios";
import createEcho from "lib/echo";
import { socket } from "config/env";
import { root } from "config/persist";
import createRootReducer from "reducers";
import createAxiosMiddleware from "middleware/axios";
import createSocketMiddelware from "middleware/socket";

/**
 *
 *
 * @type {{createHref, goBack, length, replace, go, action, location, goForward, block, push, listen}}
 */
export const history = createBrowserHistory();

/**
 * Create store
 *
 * @return {Promise<any[]>}
 */
async function configureStore() {
  // middleware
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const middlewares = [
    thunk,
    createAxiosMiddleware(axios),
    routerMiddleware(history),
  ];

  if (socket) {
    middlewares.push(createSocketMiddelware(createEcho()));
  }

  // create importItems
  const store = createStore(
    createRootReducer(history),
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  // create persist
  const persist = await persistStore(store);

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (module.hot) {
    module.hot.accept("reducers", () => {
      // This fetch the new state of the above reducers.
      const nextRootReducer = import("reducers");
      store.replaceReducer(persistReducer(root, nextRootReducer));
    });
  }

  return [store, persist];
}

export default configureStore;
