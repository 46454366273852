import * as actionTypes from "constants/actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case actionTypes.LOAD_INDICATORS_SUCCESS:
      return {
        ...state,
        ...action.response.data.reduce((reducer, question) => {
          if (state[question.id]) {
            return {
              ...reducer,
              [question.id]: {
                ...state[question.id],
                affect: question.affect,
                achieved: question.achieved,
              },
            };
          }

          return {
            ...reducer,
            [question.id]: {
              tabs: {},
              affect: question.affect,
              achieved: question.achieved,
            },
          };
        }, {}),
      };
    case actionTypes.LOAD_INDICATOR_SUCCESS:
      return {
        ...state,
        [action.response.data.id]: {
          ...state[action.response.data.id],
          rates: action.response.data.no_of_rates,
          tabs: action.response.data.questions.reduce(
            (reducer, question, index) => ({
              ...reducer,
              [index]: question.questions.reduce(
                (reducer, question) => ({
                  ...reducer,
                  [question.id]: question.achieved.rated,
                }),
                {},
              ),
            }),
            {},
          ),
        },
      };
    case actionTypes.MANUAL_REVIEW:
      return {
        ...state,
        [action.indicatorId]: {
          ...state[action.indicatorId],
          tabs: {
            ...state[action.indicatorId].tabs,
            [action.tabId]: {
              ...state[action.indicatorId].tabs[action.tabId],
              [action.questionId]: action.review,
            },
          },
        },
      };
    default:
      return state;
  }
};
