import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { remoteUrl } from "config/env";
import { getUser } from "selectors/auth";
import styles from "./RightRegion.module.scss";

function ProfileRegion({ user }) {
  return (
    <div className={styles.region}>
      <div className={styles.container}>
        <button className={styles.button}>
          <div className={styles.profiler}>
            <div className={styles["name-container"]}>
              {user.firstName} {user.lastName}
            </div>
            <div className={styles["image-container"]}>
              <div className={styles["image-region"]}>
                <div className={styles["profile-content"]}>
                  <div className={styles["short-name-container"]}>
                    {user.firstName[0]}
                    {user.lastName[0]}
                  </div>
                  <div className={styles["image-wrapper"]}>
                    <img
                      alt={user.firstName}
                      src={`${remoteUrl}/images/profile/${user.id}.jpg`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}

ProfileRegion.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  user: getUser(state),
});

export default connect(mapStateToProps)(ProfileRegion);
