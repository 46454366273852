import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import * as routes from "constants/routes";
import { resolveRedirect } from "lib/helpers";
import { isAuthorized, isTokenValid } from "selectors/auth";

function AuthRoute({ authorized, component: Component, fallback, ...props }) {
  return (
    <Route
      {...props}
      render={renderProps => {
        if (!authorized) {
          return (
            <Suspense fallback={fallback}>
              <Component {...renderProps} />
            </Suspense>
          );
        }

        const redirect = resolveRedirect(props.location.hash);
        if (redirect && redirect.pathname !== props.location.pathname) {
          return (
            <Redirect
              to={{
                ...redirect,
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <Redirect
            to={{
              pathname: routes.HOME,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

AuthRoute.propTypes = {
  path: PropTypes.string,
  authorized: PropTypes.bool,
  location: PropTypes.object,
  fallback: PropTypes.shape({
    render: PropTypes.func,
  }),
  component: PropTypes.shape({
    render: PropTypes.func,
  }),
};

const mapStateToProps = state => ({
  validToken: isTokenValid(state),
  authorized: isAuthorized(state),
});

export default connect(mapStateToProps)(AuthRoute);
