export const menus = [
  {
    icon: "Timesheet",
    name: "Timesheet",
    url: "https://dd.jeylabs.com/app/timesheet",
  },
  {
    icon: "CalendarMirrored",
    name: "Calendar",
    url: "https://dd.jeylabs.com/event",
  },
  {
    icon: "D365TalentInsight",
    name: "Talent",
    url: "https://dd.jeylabs.com/staff",
  },
  {
    icon: "ReportLibrary",
    name: "Reports",
    url: "https://dd.jeylabs.com/reports/movements",
  },
  {
    icon: "FinancialSolid",
    name: "Finance",
    url: "https://dd.jeylabs.com/infinite/account",
  },
  {
    icon: "TeamsLogoFill16",
    name: "Teams",
    url: "https://dd.jeylabs.com/team",
  },
  {
    icon: "Rocket",
    name: "Projects",
    url: "https://dd.jeylabs.com/project",
  },
  {
    icon: "PenWorkspace",
    name: "Spaces",
    url: "https://dd.jeylabs.com/wiki",
  },
  {
    icon: "Filter",
    name: "CRM",
    url: "https://dd.jeylabs.com/infinite",
  },
  {
    icon: "Certificate",
    name: "Learning",
    url: "https://dd.jeylabs.com/learning",
  },
  {
    icon: "Market",
    name: "Marketing",
    url: "https://dd.jeylabs.com/marketing/dashboard",
  },
  {
    icon: "Lifesaver",
    name: "Support",
    url: "https://dd.jeylabs.com/support",
  },
  {
    icon: "Website",
    name: "Website",
    url: "https://dd.jeylabs.com/websites",
  },
  {
    icon: "WorkforceManagement",
    name: "Workforce",
    url: "https://dd.jeylabs.com/workforce-management",
  },
];
