import { logout } from "actions/auth";
import { getUser } from "selectors/auth";

function createSocketMiddelware(client) {
  return ({ dispatch, getState }) => next => action => {
    client.channel("logout-broadcast").listen("LogoutBroadcastEvent", event => {
      const state = getState();
      const user = getUser(state);

      if (user.id === event.user.id) {
        dispatch(logout());
      }
    });

    return next(action);
  };
}

export default createSocketMiddelware;
