import React from "react";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { ReactComponent as Logo } from "assets/svg/ddLogo.svg";

function Welcome() {
  return (
    <Stack
      verticalAlign="center"
      horizontalAlign="center"
      styles={{ root: { flex: 1 } }}>
      <Logo className="logo-image" />
      <Spinner
        label="Please wait, we are setting things up to ready for you."
        size={SpinnerSize.large}
      />
    </Stack>
  );
}

export default Welcome;
