import React from "react";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import WaffleRegion from "./WaffleRegion";
import CenterRegion from "./CenterRegion";
import ProfileRegion from "./RightRegion";
// import InfoMessage from "./InfoMessage";
import styles from "./Header.module.scss";

function Header() {
  return (
    <Stack horizontal className={styles.header} horizontalAlign="space-between">
      <WaffleRegion />
      <CenterRegion />
      <ProfileRegion />
      {/*<InfoMessage />*/}
    </Stack>
  );
}

export default Header;
