module.exports = {
  timeout: 30000,
  url: process.env.PUBLIC_URL,
  name: process.env.REACT_APP_NAME,
  isDev: process.env.NODE_ENV === "development",
  // Socket
  socketHost: process.env.REACT_APP_SOCKET_HOST,
  socketPort: process.env.REACT_APP_SOCKET_PORT,
  socket: process.env.REACT_APP_SOCKET === "true",
  // URLs
  baseUrl: process.env.REACT_APP_API_URL,
  authUrl: process.env.REACT_APP_AUTH_URL,
  remoteUrl: process.env.REACT_APP_REMOTE_URL,
  callbackUrl: process.env.REACT_APP_CALLBACK_URL,
  // OAuth client
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  responseTye: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
  queryDelimiter: process.env.REACT_APP_AUTH_QUERY_DELIMITER,
};
