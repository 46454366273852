import React from "react";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import styles from "./WaffleRegion.module.scss";
import SlidePanel from "../SlidePanel";

function WaffleRegion() {
  return (
    <Stack className={styles.region}>
      <Stack className={styles.container}>
        <SlidePanel />
      </Stack>
    </Stack>
  );
}

export default WaffleRegion;
