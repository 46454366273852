import React, { Suspense, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { isAuthorized, isTokenValid } from "selectors/auth";
import * as routes from "constants/routes";
import Header from "components/Header";

function PrivateRoute({
  authorized,
  component: Component,
  fallback,
  ...props
}) {
  return (
    <Route
      {...props}
      render={renderProps => {
        if (authorized) {
          return (
            <Fragment>
              <Header />
              <Stack styles={{ root: { marginTop: 50, flex: 1 } }}>
                <Stack horizontal styles={{ root: { flex: 1 } }}>
                  <Suspense fallback={fallback}>
                    <Component {...renderProps} />
                  </Suspense>
                </Stack>
              </Stack>
            </Fragment>
          );
        }

        return (
          <Redirect
            to={{
              pathname: routes.LOGIN,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

PrivateRoute.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  authorized: PropTypes.bool,
  location: PropTypes.object,
  fallback: PropTypes.shape({
    render: PropTypes.func,
  }),
  component: PropTypes.shape({
    render: PropTypes.func,
  }),
};

const mapStateToProps = state => ({
  validToken: isTokenValid(state),
  authorized: isAuthorized(state),
});

export default connect(mapStateToProps)(PrivateRoute);
