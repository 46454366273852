import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import * as routes from "constants/routes";
import Faraway from "pages/Faraway";
import AuthRoute from "./AuthRoute";
import PrivateRoute from "./PrivateRoute";
import Loader from "./Loader";

function Routes() {
  return (
    <Switch>
      <PrivateRoute
        exact
        fallback={Loader}
        path={routes.HOME}
        component={lazy(() => import("pages/Home"))}
      />
      <PrivateRoute
        exact
        fallback={Loader}
        path={routes.REVIEWERS_STATUS}
        component={lazy(() => import("pages/ReviewersStatus"))}
      />
      <AuthRoute
        exact
        fallback={Loader}
        path={routes.LOGIN}
        component={lazy(() => import("pages/Login"))}
      />
      <AuthRoute
        exact
        fallback={Loader}
        path={routes.OAUTH_CALLBACK}
        component={lazy(() => import("pages/Authorize"))}
      />
      <PrivateRoute
        fallback={Loader}
        path={[routes.SUBMISSION_SHOW, routes.SUBMISSION_INDEX]}
        component={lazy(() => import("pages/Submission"))}
      />
      <PrivateRoute
        exact
        fallback={Loader}
        path={routes.MANUAL_APPROVAL_INDEX}
        component={lazy(() => import("pages/Approvals/Manual/List"))}
      />
      <PrivateRoute
        fallback={Loader}
        component={lazy(() => import("pages/Approvals/Manual/Show"))}
        path={[
          routes.MANUAL_APPROVAL_INDICATOR_SHOW,
          routes.MANUAL_APPROVAL_SHOW,
        ]}
      />
      <PrivateRoute
        fallback={Loader}
        path={routes.SELF_REVIEW_INDEX}
        component={lazy(() => import("pages/Approvals/Self"))}
      />
      <PrivateRoute
        fallback={Loader}
        path={routes.BOARD_REVIEW_INDEX}
        component={lazy(() => import("pages/Approvals/Board"))}
      />
      <PrivateRoute
        fallback={Loader}
        path={routes.AWARD_REVIEW_INDEX}
        component={lazy(() => import("pages/Approvals/Award"))}
      />
      <PrivateRoute
        fallback={Loader}
        path={routes.KPI_REPORTS}
        component={lazy(() => import("pages/Report"))}
      />
      <Route component={Faraway} />
    </Switch>
  );
}

export default Routes;
