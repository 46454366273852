import camelKeys from "camelcase-keys";
// App level imports
import * as actionTypes from "constants/actionTypes";

const initialState = {
  accessToken: null,
  refreshToken: null,
  tokenType: "Bearer",
  user: {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        ...camelKeys(action),
      };
    case actionTypes.AUTH_USER_LOADED:
      return {
        ...state,
        user: {
          id: action.response.data.id,
          email: action.response.data.email,
          lastName: action.response.data.last_name,
          firstName: action.response.data.first_name,
        },
      };
    default:
      return state;
  }
};
