import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Customizer } from "office-ui-fabric-react";
import { FluentCustomizations } from "@uifabric/fluent-theme";
import Routes from "./routes";
import Welcome from "./pages/Welcome";
import createStore, { history } from "./store";

function App() {
  const [store, setStore] = useState(null);
  const [persist, setPersist] = useState(null);

  useEffect(() => {
    let alive = true;
    const handleCreateStore = async () => {
      const [x, y] = await createStore();
      if (alive) {
        setStore(x);
        setPersist(y);
      }
    };
    handleCreateStore();
    return () => (alive = false);
  }, []);

  // Self welcome screen
  if (store === null || persist === null) {
    return <Welcome />;
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persist}>
        <Customizer {...FluentCustomizations}>
          <Routes history={history} />
        </Customizer>
      </PersistGate>
    </Provider>
  );
}

export default App;
