import moment from "moment";
import jwtDecode from "jwt-decode";
import { createSelector } from "reselect";

// State
const auth = state => state.auth;

// Retrieve access token from state
export const getAccessToken = createSelector(
  auth,
  ({ accessToken }) => accessToken,
);

// User state
export const getUser = createSelector(
  auth,
  ({ user }) => user,
);

// Retrieve token type from state
export const getTokenType = createSelector(
  auth,
  token => (token ? token.tokenType : null),
);

// Decode access token
export const getDecodedToken = createSelector(
  getAccessToken,
  token => (token ? jwtDecode(token) : null),
);

// Retrieve expires from state
export const getExpiresIn = createSelector(
  getDecodedToken,
  token => (token ? moment.unix(token.exp) : null),
);

// Check expires is before to now
export const isTokenValid = createSelector(
  getExpiresIn,
  expires => (expires ? expires.isSameOrAfter(moment()) : false),
);

// Check user and not expired
export const isAuthorized = createSelector(
  [getUser, isTokenValid],
  (user, expired) => (user && user.id ? expired : false),
);
