import React, { Fragment } from "react";
import { ActionButton, Icon } from "office-ui-fabric-react";
import styles from "../WaffleRegion/WaffleRegion.module.scss";
import { ReactComponent as Logo } from "assets/svg/ddLogo.svg";
import { remoteUrl } from "config/env";

const PanelHeader = ({ onMenuClick }) => (
  <Fragment>
    <ActionButton
      onClick={onMenuClick}
      className={styles["panel-collapse"]}
      styles={{ flexContainer: { justifyContent: "center" } }}>
      <Icon iconName="Waffle" className="ms-fontColor-white ms-fontSize-xl" />
    </ActionButton>
    <a
      className={styles["panel-header-link"]}
      href={remoteUrl}
      target="_blank"
      rel="noopener noreferrer">
      <div aria-hidden="true">
        <Logo style={{ width: "55%" }} />
        <Icon
          iconName="Forward"
          role="presentation"
          styles={{
            root: {
              display: "inline-block",
              color: "#000000",
              paddingLeft: "5px",
            },
          }}
        />
      </div>
    </a>
  </Fragment>
);

export default PanelHeader;
