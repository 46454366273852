import { parse } from "querystring";
import { queryDelimiter } from "config/env";
import { saveAs } from "file-saver";

export function encodeState(json) {
  try {
    return window.btoa(JSON.stringify(json));
  } catch (e) {
    return null;
  }
}

export function resolveRedirect(hash) {
  try {
    const { state } = parse(hash.replace(queryDelimiter, ""));
    const { redirect } = JSON.parse(window.atob(state));
    return JSON.parse(redirect);
  } catch (e) {
    return null;
  }
}

export function isFloat(number) {
  return Number(number) === number && number % 1 !== 0;
}

export function saveFile(response) {
  if (!response || (response && !response.file)) return false;
  const blobFile = base64ToFile(response.file, response.contentType);
  saveAs(blobFile, response.name);
}

export function base64ToFile(file, contentType) {
  const byteString = atob(file.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: contentType });
}
