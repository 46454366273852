import Storage from "redux-persist/lib/storage/session";

export const root = {
  key: "root",
  storage: Storage,
};

export const authToken = {
  key: "authToken",
  storage: Storage,
};
