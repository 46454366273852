import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { connectRouter } from "connected-react-router";
// App level imports
import { authToken } from "config/persist";
// Internal imports
import authReducer from "./authReducer";
import manualReviews from "./manualReviews";

export default history =>
  combineReducers({
    router: connectRouter(history),
    manualReviews: manualReviews,
    auth: persistReducer(authToken, authReducer),
  });
