import React, { Fragment } from "react";
import PanelItem from "./PanelItem";
import chunk from "lodash/chunk";
import styles from "../WaffleRegion/WaffleRegion.module.scss";

const PanelBody = ({ menus }) => (
  <Fragment>
    <h2 className={styles.title}>Apps</h2>
    {chunk(menus, 2).map((menuItems, rowIndex) => (
      <div className={styles.content} key={rowIndex}>
        {menuItems.map((menuItem, colIndex) => (
          <PanelItem key={colIndex} menu={menuItem} />
        ))}
      </div>
    ))}
  </Fragment>
);

export default PanelBody;
