import Echo from "laravel-echo";
import { socketPort, socketHost } from "config/env";

export default function() {
  window.io = require("socket.io-client");

  return new Echo({
    broadcaster: "socket.io",
    host: `${socketHost}:${socketPort}`,
  });
}
