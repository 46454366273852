import React, { Fragment, useState } from "react";
import { ActionButton, Icon, Panel, PanelType } from "office-ui-fabric-react";
import PropTypes from "prop-types";
import PanelHeader from "./PanelHeader";
import PanelBody from "./PanelBody";
import styles from "../WaffleRegion/WaffleRegion.module.scss";
import { menus } from "./MenuItem";

function SlidePanel({ onMenuClick }) {
  const [isShowPanel, setIsShowPanel] = useState(false);

  onMenuClick = () => {
    setIsShowPanel(true);
  };

  const hidePanel = () => {
    setIsShowPanel(false);
  };

  return (
    <Fragment>
      <ActionButton
        onClick={onMenuClick}
        className={styles.button}
        styles={{ flexContainer: { justifyContent: "center" } }}>
        <Icon iconName="Waffle" className="ms-fontColor-white ms-fontSize-xl" />
      </ActionButton>
      <Panel
        onRenderNavigation={() => <PanelHeader onMenuClick={hidePanel} />}
        onRenderBody={() => <PanelBody menus={menus} />}
        isOpen={isShowPanel}
        type={PanelType.customNear}
        onDismiss={hidePanel}
        customWidth="320px"
        isLightDismiss={true}
      />
    </Fragment>
  );
}

SlidePanel.propsType = {
  onMenuClick: PropTypes.func,
};

export default SlidePanel;
