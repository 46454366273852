import React from "react";
import styles from "../WaffleRegion/WaffleRegion.module.scss";
import { Icon } from "office-ui-fabric-react";

const PanelItem = ({ menu }) => (
  <div className={styles.item}>
    <a
      href={menu.url}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.link}>
      <div aria-hidden="true">
        <span
          className={styles["link-icon"]}
          role="presentation"
          style={{ display: "inline-block" }}>
          <Icon iconName={menu.icon} />
        </span>
      </div>
      <div className={styles["link-name"]}>
        <span>{menu.name}</span>
      </div>
    </a>
  </div>
);

export default PanelItem;
